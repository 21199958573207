<template>
  <div>
    <datepicker format="d/MM/yyyy" :value="date" @input="date = $moment($event)"></datepicker>
    {{ date }}
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

export default {
name: "Test",
  components: {
    Datepicker
  },
  data: () => ({
    date: '',
    users: [
      {
        id: 1,
        name: 'Ahmad Irfan'
      },
      {
        id: 2,
        name: 'Maulana'
      }
    ]
  })
}
</script>

<style scoped>

</style>
